<template>
    <div>
        <InlineLoader v-if="refinanciamientos.isLoading" loading text="Cargando refinanciamientos..." />
        <EmptyComponent v-else :empty="!(refinanciamientosFiltrados.length > 0)" colored text="No hay refinanciamientos disponibles">
            <template v-slot:icon>
                <v-icon style="font-size: 48px;" color="var(--v-primary-lighten3) !important">mdi-archive-plus</v-icon>
            </template>

            <v-card class="px-4 py-4 mb-4">
                <div class="px-4 py-4">
                    <DataTableComponent
                        :headers="headers"
                        :items="refinanciamientosFiltrados"
                        :tiene_paginacion="false"
                        no-gutters
                        dense
                    >
                        <template v-slot:item.cifrado_presupuestario="{item}"> 
                            {{ item.cifrado_presupuestario }}
                        </template>
                        <template v-slot:item.monto_disponible="{ item }">
                            {{ toMoneyFormat(item.monto) }}
                        </template>
                        <template v-slot:item.monto_solicitar="{ item }">
                            <v-text-field 
                                v-model="form[item.id]"
                                :error-messages="obtenerErrores[item.id]"
                                @change="$v.form[item.id]?.$touch()"
                                @blur="$v.form[item.id]?.$touch()"
                                outlined 
                                dense
                                :disabled="maximoDeProrrogas(item) || esContratoLiquidado"
                                class="text-center mt-6"
                                type="number"
                            />
                        </template>
                    </DataTableComponent>
                </div>
            </v-card>
        </EmptyComponent>
    </div>
</template>
<script>
import DataTableComponent from '@/components/DataTableComponent.vue';
import EmptyComponent from '@/components/utils/EmptyComponent.vue';
import InlineLoader from '@/components/utils/InlineLoader.vue';
import { toFixed, toMoneyFormat } from '@/utils/data';
import { Validator } from '@/utils/form-validation';
import { minValue } from 'vuelidate/lib/validators';
import { required, maxValue } from "vuelidate/lib/validators";

export default {
  name: "RefinanciamientosContrato",
  components: { DataTableComponent, EmptyComponent, InlineLoader },
  props: {
    refinanciamientos: { type: Object },
    montoTotal: { type: Number },
    tipoAjuste: { type: Number },
    esContratoLiquidado: { type: Boolean },
    seleccionado: { type: Boolean },
  },
  validations() {
    const validacionesMontos = {};
    this.refinanciamientos.data.forEach((refinanciamiento) => {
        const esDevolucion = this.montoTotal < 0; 
        if (esDevolucion || !this.seleccionado) {
            validacionesMontos[refinanciamiento.id] = { required: false, minValue: minValue(this.montoTotal), maxValue: maxValue(0) };

        }else{
            validacionesMontos[refinanciamiento.id] = { required, minValue: minValue(0), maxValue: maxValue(this.montoTotal) };
        }
    })
    return {
        form: {
            ...validacionesMontos,
        },
    };
  },
  data: () => ({
    form: {},
    esFormularioValido: false,
  }),
  computed: {
    headers() {
        return [
            { text: 'Cifrado presupuestario', value: 'cifrado_presupuestario', align: 'center', sortable: false },
            { text: 'Unidad', value: 'unidad.nombre', align: 'center', sortable: false },
            { text: 'Monto disponible ($)', value: 'monto_disponible', align: 'center', sortable: false },
            { text: 'Monto a solicitar', value: 'monto_solicitar', align: 'center', sortable: false },
        ];
    },
    obtenerErrores() {
      const errors = {};

      // Recorre cada refinanciamiento y valida
      this.refinanciamientos.data.forEach((refinanciamiento) => {
        const validador = this.$v.form[refinanciamiento.id];
        validador.$touch();

        if (!validador) return [];

        const errores = new Validator(this.$v.form[refinanciamiento.id]).detect().getResult();

        const esDevolucion = this.montoTotal < 0;
        let montoUtilizado = 0;
        for (let asignacion in this.form) {
          montoUtilizado += Number(this.form[asignacion] ?? 0);
        }

        montoUtilizado = Number(montoUtilizado.toFixed(2));
        if (
          esDevolucion &&
          this.$route.name === "prorroga-contrato" &&
          Math.abs(+this.form[refinanciamiento.id]) > +refinanciamiento.monto
        ) {
          errores.push(
            `La cantidad solicitada excede el monto disponible: $${refinanciamiento.monto}`
          );
        }
        if (esDevolucion && this.$route.name === "modificar-contrato") {
          const diferencia = -Number(
            (Math.abs(this.montoTotal) - Math.abs(montoUtilizado)).toFixed(2)
          );

          // if (toFixed(this.montoTotal) < toFixed(montoUtilizado)) {
          //   errores.push(
          //     `La sumatoria de los montos modificados es: ${diferencia}`
          //   );
          // }

          if (
            Math.abs(toFixed(montoUtilizado)) >
            Math.abs(toFixed(this.montoTotal))
          ) {
            errores.push(`La sumatoria excede el monto a modificar`);
          }
        } else {
          const diferencia = Number(+this.montoTotal - +montoUtilizado).toFixed(
            2
          );

          if (toFixed(montoUtilizado) < toFixed(this.montoTotal)) {
            errores.push(
              `La sumatoria de los montos modificados es: ${diferencia}`
            );
          }

          if (toFixed(montoUtilizado) > toFixed(this.montoTotal)) {
            errores.push(`La sumatoria excede el monto a modificar`);
          }
        }

        if (+this.form[refinanciamiento.id] > +refinanciamiento.monto) {
          errores.push(
            `La cantidad solicitada excede el monto disponible: $${refinanciamiento.monto}`
          );
        }

        // Agrega los errores al objeto de errores
        errors[refinanciamiento.id] = errores;
        // Actualiza la validez del formulario
        this.esFormularioValido = !Object.values(errors).some(
          (errArray) => errArray.length > 0
        );
      });
      return errors;
    },
    refinanciamientosFiltrados(){
      return this.refinanciamientos.data.filter(refinanciamiento => +refinanciamiento.monto !== 0);
    }

  },
  methods: {
    toMoneyFormat,
    activarEnvioFormulario() {
      this.$v.form.$touch();

      const asignaciones = Object.entries(this.form).map(([key, value]) => {
        const refinanciamiento = this.refinanciamientos.data.find((r) => r.id === +key );
        return {
          id: key,
          monto: value,
          id_fondo: refinanciamiento ? refinanciamiento.id_fondo: null,
          cifrado: refinanciamiento ? refinanciamiento.cifrado_presupuestario: null,
        };
      });
      return {
        valido: this.esFormularioValido,
        asignaciones,
      };
    },
    maximoDeProrrogas(item){
        const esProrroga = item.ajustes.filter((ajuste) => ajuste.tipo_edicion === 2)
        const tieneDosProrrogas = esProrroga.length >= 2;
        return this.tipoAjuste === 2 ? tieneDosProrrogas : false ;
    }
  },
  watch: {
    refinanciamientos: {
        handler(valor) {
            this.form = {};

            const montos = {};
            valor.data.forEach((refinanciamiento) => {
                montos[refinanciamiento.id] = null;
            });

            this.form = montos;
        },   
        deep: true,
        immediate: true,
    },
  },
};
</script>