<template>
  <Fragment>
      <v-dialog v-model="isOpen" max-width="1000px" persistent>
          <v-card>
              <v-card-title class="justify-space-between align-center">
                  <v-btn 
                      style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                      class="pt-0"
                      @click.stop="cerrarModal" 
                      text
                      rounded
                  >
                      <v-icon>mdi-window-close</v-icon>
                  </v-btn>
              </v-card-title>
              <v-divider/>
              <EmptyComponent :empty="historial.data.length === 0" 
                colored style="min-width: 100%">
                <template v-slot:empty-content>
                    <v-icon x-large>mdi-comment-off-outline</v-icon>
                    <p class="mb-2 mt-2 font-weight-bold text-body-1">
                      No cuenta con un historial.
                    </p>
                </template>
                <v-col v-for="(item, index) in historial.data" :key="index">
                  <v-card class="px-4 py-4">
                    <h5 class="text-h6 secondary--text text-uppercase font-weight-bold">
                      <v-icon class="mr-2 mt-2 mb-4">mdi-note-edit-outline</v-icon>
                      {{ 
                        item.AjusteContrato.tipo_edicion === 1 
                        ? `Modificativa ${index + 1}` 
                        : `Prorroga ${index + 1}` 
                      }} - {{ `${moment(item.created_at).format("DD/MM/YYYY hh:mm A")}` }} - {{ item.SubProcesoOb ? item.SubProcesoOb.especificacion : 'Sin lote' }}
                    </h5>
                    <!-- <span class="text-h6 font-weight-bold"> {{`Fecha y hora de modificativa: ${moment(item.created_at).format("DD/MM/YYYY hh:mm A")}`}}</span> -->
                    <span class="text-h6 font-weight-bold"> {{`Obs: ${item.LotesProceso ? item.LotesProceso.nombre : '-'}`}}</span>
                    <br>
                    <span class="text-h6 font-weight-bold"> {{`Cantidad: ${item.cantidad_actual}`}}</span>
                    <br>
                    <span class="text-h6 font-weight-bold"> {{`Monto actual: ${item.monto_actual}`}}</span>
                    <br>
                    <span class="text-h6 font-weight-bold"> {{`Comentario: ${item.comentario}`}}</span>
                    <br>
                    <span class="text-h6 font-weight-bold"> {{`Plazo actual: ${item.plazo_actual ? item.plazo_actual : item.plazo_anterior} `}}</span>
                  </v-card>
                </v-col>
              </EmptyComponent> 
              <v-card-text>
                  <div class="d-flex justify-center align-center" style="position: sticky; bottom: 0; background: white; gap: 16px;">
                      <v-btn 
                          class="flex-grow-1 flex-shrink-1 mt-4" 
                          color="secondary" 
                          large
                          @click.stop="cerrarModal"
                      >
                          Cerrar
                      </v-btn>
                  </div>
              </v-card-text>
          </v-card>
      </v-dialog>
  </Fragment>
</template>
<script>
import EmptyComponent from '@/components/utils/EmptyComponent.vue';
import { Fragment } from 'vue-fragment';

export default {
  name: "ModalHistorial",
  components: { Fragment, EmptyComponent },
  props: {
    isOpen: { type: Boolean },
    historial: { type: Object },
  },
  data: () => ({
  }),
  methods: {
    cerrarModal() {
      this.$emit("close");
    },

  },
  watch: {},
};
</script>